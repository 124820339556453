<template>
  <section>
    <OrderComponent :data="bought" :loading="processing">
      <template v-slot:data>
        <div class="grid pricing bottommargin container">
          <order-card-component
            v-for="item in bought"
            :key="item.id"
            :item="item"
            :currency="channelSettings.currency"
            :expired="isExpired(item.status)"
            class="pricing-box pr-2 pl-2"
            @order-updated="getOrders"
          />
        </div>
      </template>
      <template v-slot:empty>
        <div class="mt-5 mb-5 text-center">
          <h3>You do not have purchased events yet</h3>
        </div>
      </template>
    </OrderComponent>
    <div>
      <static-block-component
        page="profile"
        position="top"
      ></static-block-component>
      <app-link to="/schedule" class="w-100 btn btn-block linked-button"
        >{{ $t('Watch') }} upcoming events</app-link
      >
    </div>
  </section>
</template>

<script>
import { mapCacheActions } from 'vuex-cache';
import { mapState } from 'vuex';
import { ORDER_STATUS } from '../../const';
import OrderCardComponent from '@/components/profile/OrderCardComponent';
import StaticBlockComponent from '@/components/StaticBlockComponent';
import OrderComponent from '@/components/profile/OrdersComponent';
import AppLink from '@/components/base-components/AppLink';

export default {
  name: 'SubscriptionComponent',
  components: {
    OrderCardComponent,
    OrderComponent,
    StaticBlockComponent,
    AppLink,
  },
  data() {
    return {
      bought: [],
      processing: false,
    };
  },
  computed: {
    ...mapState({
      channelSettings: (state) => state.channel.settings,
    }),
  },
  methods: {
    ...mapCacheActions(['GET_ORDERS']),
    isExpired(status) {
      return status === ORDER_STATUS.expired;
    },
    getOrders() {
      this.processing = true;
      Promise.allSettled([
        this.GET_ORDERS({ status: ORDER_STATUS.paid }),
        this.GET_ORDERS({ status: ORDER_STATUS.expired }),
      ])
        .then(([paid, expired]) => {
          this.bought = [...paid.value, ...expired.value];
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
  mounted() {
    this.getOrders();
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  gap: 0.5rem;
  padding: 1rem 0;
}
</style>
